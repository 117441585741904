<script>
import Tabulation from "@/view/tabulate/Tabulate.vue";
import SearchCombination from "@/view/search/SearchCombination.vue";
import {download} from "@/utils/downLoad";
export default {
  components: {SearchCombination, Tabulation},
  data(){
    return{
      tableData: null,
      pageSize: 20,
      pageNum: 1,
      total: 0,
    }
  },
  created() {
    this.getBlackList()
  },
  methods: {
    /**
     * 获取黑名单列表
     * @param data {Object} 查询条件
     * @param mod {boolean} 是否有提示
     * @param pageNum {number | null} 页码
     */
    getBlackList(data = null, mod = false, pageNum = null){
      this.axios.get(this.$api.publicUrl.OrderStat, {
        params: {
          pageSize: this.pageSize,
          pageNum: pageNum || this.pageNum,
          ...data
        }
      }).then(res =>{
        if(res.data.status === 200){
          this.tableData = res.data.data
          this.total = res.data.data.total
          if(mod){
            this.$message.success("查询成功！")
          }
        }else {
          this.$message(res.data.data.message)
        }
      })
    },
    /**
     * 1、创建模板数据； 2、调用创建弹出层的方法
     * @param ti  {string}  标题
     * @param mod {string}  方式；例如 添加、修改
     * @param data  {Object}  源数据； 最终弹出层的回调事件会将修改后的源数据返回，以便于后续提交表单数据
     */
    createTemplateData(ti, mod, data = null){
      this.$createPopup("form",{
        ti: ti,
        tree: [
          [{label: "昵称", type: "text", key: "nickname", prop: "nickname"}, {label: "姓名", type: "text", key: "userName", prop: "userName"}],
          [{label: "电话", type: "number", key: "phoneNumber", prop: "phoneNumber"}, {label: "来源", type: "select", key: "userSource", prop: "userSource",
            options: [
              {value: 'HTML', label: 'HTML'},
              {value: 'CSS', label: 'CSS'},
              {value: 'JavaScript', label: 'JavaScript'}
            ]}
          ],
          [{label: "拉黑原因", type: "textarea", key: "remark", prop: "remark"}]
        ],
        formData: data || {
          nickname: "",
          userName: "",
          phoneNumber: "",
          userSource: "",
          remark: "",
        },
        rules: {
          userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          phoneNumber: [{ required: true, message: '请输入电话', trigger: 'blur' }],
          remark: [{ required: true, message: '请输入拉黑原因', trigger: 'blur'}],
        }
      }, mod, this.poPupCallback)
    },
    /**
     * 搜索组件的回调
     * @param data {Object} 搜索的条件数据
     */
    searchCallBack(data){
      this.getBlackList(data, true, 1)
    },
    /**
     * 导出
     */
    orderExport(){
      this.$createPopup("form",{
        ti: "订单导出",
        tree: [
          [{label: "日期范围", type: "daterange", key: "dateRange", prop: "dateRange", format: null, valueFormat: "yyyy-MM-dd",
            pickerOptions: {
              onPick: ({ maxDate, minDate }) => {
                this.choiceDate = minDate.getTime()
                if (maxDate) this.choiceDate = ''
              },
              disabledDate: time => {
                if (this.choiceDate) {
                  const one = 15 * 24 * 3600 * 1000
                  const minTime = this.choiceDate - one
                  const maxTime = this.choiceDate + one
                  return (
                    time.getTime() < minTime ||
                    time.getTime() > maxTime ||
                    time.getTime() > Date.now()
                  )
                } else {
                  return time.getTime() > Date.now()
                }
              }
            }}],
        ],
        formData: {
          dateRange: "",
        },
        rules: {
          dateRange: [{ required: true, message: '请选择日期区间', trigger: 'change'}],
        }
      }, null, this.poPupCallback)
    },
    /**
     * 弹出层回调方法
     * @param data
     */
    poPupCallback(data){
      this.sendService({
        startTime: data.dateRange[0],
        stopTime: data.dateRange[1]
      }, data.dateRange)
    },
    /**
     * 向服务器发送请求
     * @param data
     * @param ti
     */
    sendService(data, ti){
      download(data, `${ti}订单数据`, "post", this.$api.publicUrl.OrderExport)
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.getBlackList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getBlackList()
    }
  }
}
</script>

<template>
  <fragment v-if="tableData">
    <h1 class="title">请输入查询条件</h1>
    <section class="search">
      <SearchCombination :data="{
        list: [
          [
            {ti: '用户名字', type: 'input', class: 'min', key: 'userName'},
            {ti: '来源', type: 'select', class: 'min', key: 'orderSource', options: [
              {value: '哈啰', label: '哈啰'},
              {value: '携程', label: '携程'},
              {value: '飞猪', label: '飞猪'},
              {value: '租租车', label: '租租车'},
              {value: '同行', label: '同行'},
              {value: '线下', label: '线下'},
            ]},
            {ti: '日期区间', type: 'datePicker', class: 'min', key: ['startTime', 'stopTime']},
          ]
        ],
        senior: false
      }" @searchCallBack="searchCallBack"></SearchCombination>
    </section>
    <section class="control">
      <div class="con_ti"></div>
    </section>
    <section class="tabulation" v-if="tableData">
      <Tabulation :data="{
        columnName: [
          {name: '员工姓名', prop: 'userName', type: 'text', needSort: false},
          {name: '发车数量', prop: 'handoverSum', type: 'text', needSort: false},
          {name: '收车数量', prop: 'returnSum', type: 'text', needSort: false},
          {name: '续租数量', prop: 'delaySum', type: 'text', needSort: false},
          {name: '好评数量', prop: 'goodSum', type: 'text', needSort: false},
        ],
        rows: this.tableData,
        control: []
      }"></Tabulation>
    </section>
  </fragment>
  <fragment v-else>
    <img src="../../../assets/images/loading.gif" class="loading" height="100" width="100" alt="加载中"/>
  </fragment>
</template>
<style src="@/assets/css/componentStyle.css" scoped></style>
